import flatpickr from "flatpickr";

$(document).on("turbolinks:load", function () {
  const datePickerStart = $('.flatpickr.vacation-start');
  const datePickerEnd = $('.flatpickr.vacation-end');
  const datePickerOptsStart = {
    altInput: true,
    altFormat: "l, F j, Y H:i",
    dateFormat: "Y-m-d H:i",
    monthSelectorType: "static",
    prevArrow: "&slarr;",
    nextArrow: "&srarr;",
    onChange: function (selectedDates, dateStr, instance) {
      console.log("START", selectedDates[0])
      const startsAtElem = document.getElementById("vacation_starts_at");
      startsAtElem.value = selectedDates[0].toISOString();
    },
    wrap: true,
    enableTime: true,
    time_24hr: true,
    locale: {
      firstDayOfWeek: 1
    }
  };

  const datePickerOptsEnd = {
    altInput: true,
    altFormat: "l, F j, Y H:i",
    dateFormat: "Y-m-d H:i",
    monthSelectorType: "static",
    prevArrow: "&slarr;",
    nextArrow: "&srarr;",
    onChange: function (selectedDates, dateStr, instance) {
      console.log("END", selectedDates[0])
      const endsAtElem = document.getElementById("vacation_ends_at");
      endsAtElem.value = selectedDates[0].toISOString();
    },
    wrap: true,
    enableTime: true,
    time_24hr: true,
    locale: {
      firstDayOfWeek: 1
    }
  };

  flatpickr(datePickerStart, datePickerOptsStart);
  flatpickr(datePickerEnd, datePickerOptsEnd);

  if (document.getElementsByClassName("vacation-start-input input").length > 1) {
    document.getElementsByClassName("vacation-start-input input")[1].remove();
  }

  if (document.getElementsByClassName("vacation-end-input input").length > 1) {
    document.getElementsByClassName("vacation-end-input input")[1].remove();
  }
});
